var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Asset Book" } },
                    [
                      _c(
                        "a-tabs",
                        {
                          staticClass: "hide-border",
                          attrs: { "default-active-key": 1, type: "card" }
                        },
                        [
                          _c(
                            "a-tab-pane",
                            {
                              key: 1,
                              staticStyle: {
                                padding: "5px",
                                "padding-top": "10px"
                              },
                              attrs: { tab: "List of Books" }
                            },
                            [
                              _c(
                                "a-row",
                                [
                                  _c(
                                    "a-col",
                                    {
                                      attrs: {
                                        span: 12,
                                        xs: 24,
                                        sm: 24,
                                        md: 12
                                      }
                                    },
                                    [
                                      _c(
                                        "a-col",
                                        {
                                          attrs: {
                                            span: 16,
                                            xs: 18,
                                            sm: 20,
                                            md: 16
                                          }
                                        },
                                        [
                                          _c("InputSearch", {
                                            staticStyle: { width: "98%" },
                                            on: {
                                              "input-search":
                                                _vm.reponseSearchInput
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "a-button",
                                        {
                                          attrs: { type: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.ResetFilter()
                                            }
                                          }
                                        },
                                        [
                                          _c("a-icon", {
                                            staticStyle: {
                                              cursor: "pointer",
                                              "font-size": "large",
                                              transform: "scaleX(-1)",
                                              "-moz-transform": "scaleX(-1)",
                                              "-webkit-transform": "scaleX(-1)",
                                              "-ms-transform": "scaleX(-1)"
                                            },
                                            attrs: { type: "reload" }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { span: 24 }
                                    },
                                    [
                                      _c("TableCustom", {
                                        attrs: {
                                          dataSource: _vm.dataSource,
                                          columns: _vm.columns,
                                          defaultPagination: false,
                                          paginationcustom: true,
                                          scroll: { y: 400, x: 1200 },
                                          loading: _vm.loadingTable
                                        },
                                        on: {
                                          "on-tablechange": _vm.onSorterChange,
                                          "on-edit": _vm.responseEditTable,
                                          "on-view": _vm.responseViewTable
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "a-space",
                                        {
                                          staticStyle: { height: "50px" },
                                          attrs: { align: "center" }
                                        },
                                        [
                                          _c("Pagination", {
                                            attrs: {
                                              total: _vm.totalData,
                                              pageSizeSet: _vm.limit,
                                              idPagination: "pagination1"
                                            },
                                            on: {
                                              "response-pagesize-change":
                                                _vm.responsePageSizeChange,
                                              "response-currentpage-change":
                                                _vm.responseCurrentPageChange
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    {
                                      staticClass: "mt-2",
                                      attrs: { span: 12, align: "end" }
                                    },
                                    [
                                      _c(
                                        "a-tag",
                                        {
                                          staticStyle: { "font-size": "13px" },
                                          attrs: { color: "#8c8c8c" }
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("lbl_total_found")
                                              ) +
                                              " : " +
                                              _vm._s(_vm.totalData) +
                                              " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _vm.$can("create", "asset-book")
                            ? _c(
                                "a-tab-pane",
                                {
                                  key: 2,
                                  staticStyle: { padding: "10px" },
                                  attrs: { tab: _vm.mode + " Book" }
                                },
                                [
                                  _c(
                                    "a-row",
                                    [
                                      _c(
                                        "a-col",
                                        { attrs: { span: 24 } },
                                        [
                                          _c(
                                            "a-form",
                                            {
                                              attrs: {
                                                form: _vm.form,
                                                "label-col": { span: 8 },
                                                "wrapper-col": { span: 14 },
                                                layout: "vertical"
                                              },
                                              on: {
                                                submit: function($event) {
                                                  $event.preventDefault()
                                                  return _vm.submitForm.apply(
                                                    null,
                                                    arguments
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "a-row",
                                                [
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "a-form-model-item",
                                                        {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              _vm.formRules.book
                                                                .label
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _c("a-input", {
                                                            directives: [
                                                              {
                                                                name:
                                                                  "decorator",
                                                                rawName:
                                                                  "v-decorator",
                                                                value:
                                                                  _vm.formRules
                                                                    .book
                                                                    .decorator,
                                                                expression:
                                                                  "formRules.book.decorator"
                                                              }
                                                            ],
                                                            attrs: {
                                                              name:
                                                                _vm.formRules
                                                                  .book.name,
                                                              placeholder: _vm.$t(
                                                                _vm.formRules
                                                                  .book
                                                                  .placeholder
                                                              ),
                                                              autocomplete:
                                                                "off"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "a-form-model-item",
                                                        {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              _vm.formRules.type
                                                                .label
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "a-select",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "decorator",
                                                                  rawName:
                                                                    "v-decorator",
                                                                  value:
                                                                    _vm
                                                                      .formRules
                                                                      .type
                                                                      .decorator,
                                                                  expression:
                                                                    "formRules.type.decorator"
                                                                }
                                                              ],
                                                              attrs: {
                                                                name:
                                                                  _vm.formRules
                                                                    .type.name,
                                                                placeholder: _vm.$t(
                                                                  _vm.formRules
                                                                    .type
                                                                    .placeholder
                                                                ),
                                                                allowClear: true
                                                              },
                                                              on: {
                                                                change:
                                                                  _vm.handleTypeForCorporateBook
                                                              }
                                                            },
                                                            _vm._l(
                                                              _vm.dataBookType,
                                                              function(
                                                                data,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "a-select-option",
                                                                  {
                                                                    key: index,
                                                                    attrs: {
                                                                      value:
                                                                        data.value
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "a-tooltip",
                                                                      [
                                                                        _c(
                                                                          "template",
                                                                          {
                                                                            slot:
                                                                              "title"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.value
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              data.value
                                                                            ) +
                                                                            " "
                                                                        )
                                                                      ],
                                                                      2
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.related
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .relatedCorporateBook
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .relatedCorporateBook
                                                                          .decorator,
                                                                      expression:
                                                                        "\n                              formRules.relatedCorporateBook.decorator\n                            "
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .relatedCorporateBook
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .relatedCorporateBook
                                                                        .placeholder
                                                                    ),
                                                                    showSearch: true,
                                                                    "option-filter-prop":
                                                                      "children",
                                                                    "filter-option":
                                                                      _vm.filterOption,
                                                                    loading:
                                                                      _vm.loadingCorporate,
                                                                    allowClear: true
                                                                  },
                                                                  on: {
                                                                    search: function(
                                                                      value
                                                                    ) {
                                                                      return _vm.handleSearchCorporateBook(
                                                                        value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.dataCorporateBook,
                                                                  function(
                                                                    data,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          value:
                                                                            data.id
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-tooltip",
                                                                          [
                                                                            _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "title"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data.name +
                                                                                        " " +
                                                                                        data.type
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.name +
                                                                                    " " +
                                                                                    data.type
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.related
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .copyAdditions
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-switch", {
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .copyAdditions
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .copyAdditions
                                                                      .placeholder
                                                                  )
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.copyAdditions,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.copyAdditions = $$v
                                                                  },
                                                                  expression:
                                                                    "copyAdditions"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.related
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .copyAdjustment
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-switch", {
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .copyAdjustment
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .copyAdjustment
                                                                      .placeholder
                                                                  )
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.copyAdjustment,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.copyAdjustment = $$v
                                                                  },
                                                                  expression:
                                                                    "copyAdjustment"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.related
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .copyRetirement
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c("a-switch", {
                                                                attrs: {
                                                                  name:
                                                                    _vm
                                                                      .formRules
                                                                      .copyRetirement
                                                                      .name,
                                                                  placeholder: _vm.$t(
                                                                    _vm
                                                                      .formRules
                                                                      .copyRetirement
                                                                      .placeholder
                                                                  )
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.copyRetirement,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.copyRetirement = $$v
                                                                  },
                                                                  expression:
                                                                    "copyRetirement"
                                                                }
                                                              })
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.mode === "Add New"
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .posgainaccount
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .posgainaccount
                                                                          .decorator,
                                                                      expression:
                                                                        "formRules.posgainaccount.decorator"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .posgainaccount
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .posgainaccount
                                                                        .placeholder
                                                                    ),
                                                                    showSearch: true,
                                                                    "option-filter-prop":
                                                                      "children",
                                                                    "filter-option":
                                                                      _vm.filterOption,
                                                                    allowClear: true
                                                                  },
                                                                  on: {
                                                                    search: function(
                                                                      value
                                                                    ) {
                                                                      return _vm.handleSearchCoa(
                                                                        value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.dataCoa
                                                                    .data,
                                                                  function(
                                                                    data,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          value:
                                                                            data.id
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-tooltip",
                                                                          [
                                                                            _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "title"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data.code +
                                                                                        " " +
                                                                                        data.description
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.code +
                                                                                    " " +
                                                                                    data.description
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.mode === "Add New"
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .poslossnaccount
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .poslossnaccount
                                                                          .decorator,
                                                                      expression:
                                                                        "formRules.poslossnaccount.decorator"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .poslossnaccount
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .poslossnaccount
                                                                        .placeholder
                                                                    ),
                                                                    showSearch: true,
                                                                    "option-filter-prop":
                                                                      "children",
                                                                    "filter-option":
                                                                      _vm.filterOption,
                                                                    allowClear: true
                                                                  },
                                                                  on: {
                                                                    search: function(
                                                                      value
                                                                    ) {
                                                                      return _vm.handleSearchCoa(
                                                                        value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.dataCoa
                                                                    .data,
                                                                  function(
                                                                    data,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          value:
                                                                            data.id
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-tooltip",
                                                                          [
                                                                            _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "title"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data.code +
                                                                                        " " +
                                                                                        data.description
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.code +
                                                                                    " " +
                                                                                    data.description
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.mode === "Add New"
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .posClearingAccount
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .posClearingAccount
                                                                          .decorator,
                                                                      expression:
                                                                        "\n                              formRules.posClearingAccount.decorator\n                            "
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .posClearingAccount
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .posClearingAccount
                                                                        .placeholder
                                                                    ),
                                                                    showSearch: true,
                                                                    "option-filter-prop":
                                                                      "children",
                                                                    "filter-option":
                                                                      _vm.filterOption,
                                                                    allowClear: true
                                                                  },
                                                                  on: {
                                                                    search: function(
                                                                      value
                                                                    ) {
                                                                      return _vm.handleSearchCoa(
                                                                        value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.dataCoa
                                                                    .data,
                                                                  function(
                                                                    data,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          value:
                                                                            data.id
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-tooltip",
                                                                          [
                                                                            _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "title"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data.code +
                                                                                        " " +
                                                                                        data.description
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.code +
                                                                                    " " +
                                                                                    data.description
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.mode === "Add New"
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .nbvrgainaccount
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .nbvrgainaccount
                                                                          .decorator,
                                                                      expression:
                                                                        "formRules.nbvrgainaccount.decorator"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .nbvrgainaccount
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .nbvrgainaccount
                                                                        .placeholder
                                                                    ),
                                                                    showSearch: true,
                                                                    "option-filter-prop":
                                                                      "children",
                                                                    "filter-option":
                                                                      _vm.filterOption,
                                                                    allowClear: true
                                                                  },
                                                                  on: {
                                                                    search: function(
                                                                      value
                                                                    ) {
                                                                      return _vm.handleSearchCoa(
                                                                        value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.dataCoa
                                                                    .data,
                                                                  function(
                                                                    data,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          value:
                                                                            data.id
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-tooltip",
                                                                          [
                                                                            _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "title"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data.code +
                                                                                        " " +
                                                                                        data.description
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.code +
                                                                                    " " +
                                                                                    data.description
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _vm.mode === "Add New"
                                                        ? _c(
                                                            "a-form-model-item",
                                                            {
                                                              attrs: {
                                                                label: _vm.$t(
                                                                  _vm.formRules
                                                                    .nbvrlossaccount
                                                                    .label
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "a-select",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name:
                                                                        "decorator",
                                                                      rawName:
                                                                        "v-decorator",
                                                                      value:
                                                                        _vm
                                                                          .formRules
                                                                          .nbvrlossaccount
                                                                          .decorator,
                                                                      expression:
                                                                        "formRules.nbvrlossaccount.decorator"
                                                                    }
                                                                  ],
                                                                  attrs: {
                                                                    name:
                                                                      _vm
                                                                        .formRules
                                                                        .nbvrlossaccount
                                                                        .name,
                                                                    placeholder: _vm.$t(
                                                                      _vm
                                                                        .formRules
                                                                        .nbvrlossaccount
                                                                        .placeholder
                                                                    ),
                                                                    showSearch: true,
                                                                    "option-filter-prop":
                                                                      "children",
                                                                    "filter-option":
                                                                      _vm.filterOption,
                                                                    allowClear: true
                                                                  },
                                                                  on: {
                                                                    search: function(
                                                                      value
                                                                    ) {
                                                                      return _vm.handleSearchCoa(
                                                                        value
                                                                      )
                                                                    }
                                                                  }
                                                                },
                                                                _vm._l(
                                                                  _vm.dataCoa
                                                                    .data,
                                                                  function(
                                                                    data,
                                                                    index
                                                                  ) {
                                                                    return _c(
                                                                      "a-select-option",
                                                                      {
                                                                        key: index,
                                                                        attrs: {
                                                                          value:
                                                                            data.id
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "a-tooltip",
                                                                          [
                                                                            _c(
                                                                              "template",
                                                                              {
                                                                                slot:
                                                                                  "title"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      data.code +
                                                                                        " " +
                                                                                        data.description
                                                                                    ) +
                                                                                    " "
                                                                                )
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  data.code +
                                                                                    " " +
                                                                                    data.description
                                                                                ) +
                                                                                " "
                                                                            )
                                                                          ],
                                                                          2
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  }
                                                                ),
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "a-form-model-item",
                                                        {
                                                          attrs: {
                                                            label: _vm.$t(
                                                              _vm.formRules
                                                                .active.label
                                                            )
                                                          }
                                                        },
                                                        [
                                                          _c("a-switch", {
                                                            attrs: {
                                                              name:
                                                                _vm.formRules
                                                                  .active.name,
                                                              placeholder: _vm.$t(
                                                                _vm.formRules
                                                                  .active
                                                                  .placeholder
                                                              )
                                                            },
                                                            model: {
                                                              value: _vm.active,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.active = $$v
                                                              },
                                                              expression:
                                                                "active"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "a-col",
                                                    { attrs: { span: 24 } },
                                                    [
                                                      _c(
                                                        "a-form-item",
                                                        [
                                                          _c(
                                                            "a-space",
                                                            [
                                                              _c(
                                                                "a-button",
                                                                {
                                                                  attrs: {
                                                                    type:
                                                                      "danger"
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      _vm.handleCancelButton
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          "lbl_cancel"
                                                                        )
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              ),
                                                              _vm.$can(
                                                                "create",
                                                                "asset-book"
                                                              ) ||
                                                              _vm.$can(
                                                                "update",
                                                                "asset-book"
                                                              )
                                                                ? _c(
                                                                    "a-button",
                                                                    {
                                                                      key:
                                                                        "submit",
                                                                      attrs: {
                                                                        type:
                                                                          "primary",
                                                                        loading:
                                                                          _vm.isFormSubmitted,
                                                                        "html-type":
                                                                          "submit"
                                                                      },
                                                                      on: {
                                                                        click: function(
                                                                          $event
                                                                        ) {
                                                                          return _vm.submitForm(
                                                                            "Add New"
                                                                          )
                                                                        }
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            _vm.mode ===
                                                                              "Add New"
                                                                              ? "Save"
                                                                              : "Update"
                                                                          ) +
                                                                          " "
                                                                      )
                                                                    ]
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-modal",
            {
              attrs: {
                maskClosable: false,
                keyboard: false,
                title: _vm.disabledActive
                  ? "View Asset Book"
                  : "Edit Asset Book"
              },
              on: { ok: _vm.handleOk },
              model: {
                value: _vm.visible,
                callback: function($$v) {
                  _vm.visible = $$v
                },
                expression: "visible"
              }
            },
            [
              _c(
                "a-form",
                {
                  attrs: {
                    form: _vm.formModal,
                    "label-col": { span: 8 },
                    "wrapper-col": { span: 14 },
                    layout: "vertical"
                  },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.submitForm.apply(null, arguments)
                    }
                  }
                },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRulesModal.book.label)
                              }
                            },
                            [
                              _c("a-input", {
                                directives: [
                                  {
                                    name: "decorator",
                                    rawName: "v-decorator",
                                    value: _vm.formRulesModal.book.decorator,
                                    expression: "formRulesModal.book.decorator"
                                  }
                                ],
                                attrs: {
                                  name: _vm.formRulesModal.book.name,
                                  placeholder: _vm.$t(
                                    _vm.formRulesModal.book.placeholder
                                  ),
                                  autocomplete: "off",
                                  disabled: ""
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRulesModal.type.label)
                              }
                            },
                            [
                              _c(
                                "a-select",
                                {
                                  directives: [
                                    {
                                      name: "decorator",
                                      rawName: "v-decorator",
                                      value: _vm.formRulesModal.type.decorator,
                                      expression:
                                        "formRulesModal.type.decorator"
                                    }
                                  ],
                                  attrs: {
                                    name: _vm.formRulesModal.type.name,
                                    placeholder: _vm.$t(
                                      _vm.formRulesModal.type.placeholder
                                    ),
                                    allowClear: true,
                                    disabled: ""
                                  },
                                  on: {
                                    change: _vm.handleTypeForCorporateBookModal
                                  }
                                },
                                _vm._l(_vm.dataBookType, function(data, index) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: index,
                                      attrs: { value: data.value }
                                    },
                                    [
                                      _c(
                                        "a-tooltip",
                                        [
                                          _c("template", { slot: "title" }, [
                                            _vm._v(
                                              " " + _vm._s(data.value) + " "
                                            )
                                          ]),
                                          _vm._v(" " + _vm._s(data.value) + " ")
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.relatedModal
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.relatedCorporateBook
                                        .label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRulesModal
                                              .relatedCorporateBook.decorator,
                                          expression:
                                            "formRulesModal.relatedCorporateBook.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRulesModal
                                            .relatedCorporateBook.name,
                                        placeholder: _vm.$t(
                                          _vm.formRulesModal
                                            .relatedCorporateBook.placeholder
                                        ),
                                        showSearch: true,
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        loading: _vm.loadingCorporate,
                                        allowClear: true,
                                        disabled: ""
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.handleSearchCorporateBook(
                                            value
                                          )
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataCorporateBook, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s("" + data.name) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s("" + data.name) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.relatedModal
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.copyAdditions.label
                                    )
                                  }
                                },
                                [
                                  _c("a-switch", {
                                    attrs: {
                                      name:
                                        _vm.formRulesModal.copyAdditions.name,
                                      placeholder: _vm.$t(
                                        _vm.formRulesModal.copyAdditions
                                          .placeholder
                                      ),
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.copyAdditionsModal,
                                      callback: function($$v) {
                                        _vm.copyAdditionsModal = $$v
                                      },
                                      expression: "copyAdditionsModal"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.relatedModal
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.copyAdjustment.label
                                    )
                                  }
                                },
                                [
                                  _c("a-switch", {
                                    attrs: {
                                      name:
                                        _vm.formRulesModal.copyAdjustment.name,
                                      placeholder: _vm.$t(
                                        _vm.formRulesModal.copyAdjustment
                                          .placeholder
                                      ),
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.copyAdjustmentModal,
                                      callback: function($$v) {
                                        _vm.copyAdjustmentModal = $$v
                                      },
                                      expression: "copyAdjustmentModal"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.relatedModal
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.copyRetirement.label
                                    )
                                  }
                                },
                                [
                                  _c("a-switch", {
                                    attrs: {
                                      name:
                                        _vm.formRulesModal.copyRetirement.name,
                                      placeholder: _vm.$t(
                                        _vm.formRulesModal.copyRetirement
                                          .placeholder
                                      ),
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.copyRetirementModal,
                                      callback: function($$v) {
                                        _vm.copyRetirementModal = $$v
                                      },
                                      expression: "copyRetirementModal"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.mode === "Add New"
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.posgainaccount.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRulesModal.posgainaccount
                                              .decorator,
                                          expression:
                                            "formRulesModal.posgainaccount.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRulesModal.posgainaccount
                                            .name,
                                        placeholder: _vm.$t(
                                          _vm.formRulesModal.posgainaccount
                                            .placeholder
                                        ),
                                        showSearch: true,
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        allowClear: true,
                                        disabled: ""
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.handleSearchCoa(value)
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataCoa.data, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.code +
                                                          " " +
                                                          data.description
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.code +
                                                      " " +
                                                      data.description
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.mode === "Add New"
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.poslossnaccount.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRulesModal.poslossnaccount
                                              .decorator,
                                          expression:
                                            "formRulesModal.poslossnaccount.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRulesModal.poslossnaccount
                                            .name,
                                        placeholder: _vm.$t(
                                          _vm.formRulesModal.poslossnaccount
                                            .placeholder
                                        ),
                                        showSearch: true,
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        allowClear: true,
                                        disabled: ""
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.handleSearchCoa(value)
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataCoa.data, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.code +
                                                          " " +
                                                          data.description
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.code +
                                                      " " +
                                                      data.description
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.mode === "Add New"
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.posClearingAccount
                                        .label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRulesModal
                                              .posClearingAccount.decorator,
                                          expression:
                                            "formRulesModal.posClearingAccount.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRulesModal.posClearingAccount
                                            .name,
                                        placeholder: _vm.$t(
                                          _vm.formRulesModal.posClearingAccount
                                            .placeholder
                                        ),
                                        showSearch: true,
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        allowClear: true,
                                        disabled: ""
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.handleSearchCoa(value)
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataCoa.data, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.code +
                                                          " " +
                                                          data.description
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.code +
                                                      " " +
                                                      data.description
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.mode === "Add New"
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.nbvrgainaccount.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRulesModal.nbvrgainaccount
                                              .decorator,
                                          expression:
                                            "formRulesModal.nbvrgainaccount.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRulesModal.nbvrgainaccount
                                            .name,
                                        placeholder: _vm.$t(
                                          _vm.formRulesModal.nbvrgainaccount
                                            .placeholder
                                        ),
                                        showSearch: true,
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        allowClear: true,
                                        disabled: ""
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.handleSearchCoa(value)
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataCoa.data, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.code +
                                                          " " +
                                                          data.description
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.code +
                                                      " " +
                                                      data.description
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm.mode === "Add New"
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t(
                                      _vm.formRulesModal.nbvrlossaccount.label
                                    )
                                  }
                                },
                                [
                                  _c(
                                    "a-select",
                                    {
                                      directives: [
                                        {
                                          name: "decorator",
                                          rawName: "v-decorator",
                                          value:
                                            _vm.formRulesModal.nbvrlossaccount
                                              .decorator,
                                          expression:
                                            "formRulesModal.nbvrlossaccount.decorator"
                                        }
                                      ],
                                      attrs: {
                                        name:
                                          _vm.formRulesModal.nbvrlossaccount
                                            .name,
                                        placeholder: _vm.$t(
                                          _vm.formRulesModal.nbvrlossaccount
                                            .placeholder
                                        ),
                                        showSearch: true,
                                        "option-filter-prop": "children",
                                        "filter-option": _vm.filterOption,
                                        allowClear: true,
                                        disabled: ""
                                      },
                                      on: {
                                        search: function(value) {
                                          return _vm.handleSearchCoa(value)
                                        }
                                      }
                                    },
                                    _vm._l(_vm.dataCoa.data, function(
                                      data,
                                      index
                                    ) {
                                      return _c(
                                        "a-select-option",
                                        {
                                          key: index,
                                          attrs: { value: data.id }
                                        },
                                        [
                                          _c(
                                            "a-tooltip",
                                            [
                                              _c(
                                                "template",
                                                { slot: "title" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        data.code +
                                                          " " +
                                                          data.description
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    data.code +
                                                      " " +
                                                      data.description
                                                  ) +
                                                  " "
                                              )
                                            ],
                                            2
                                          )
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t(_vm.formRulesModal.active.label)
                              }
                            },
                            [
                              _c("a-switch", {
                                attrs: {
                                  name: _vm.formRulesModal.active.name,
                                  placeholder: _vm.$t(
                                    _vm.formRulesModal.active.placeholder
                                  ),
                                  disabled: _vm.disabledActive
                                },
                                model: {
                                  value: _vm.active,
                                  callback: function($$v) {
                                    _vm.active = $$v
                                  },
                                  expression: "active"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "a-button",
                    {
                      key: "back",
                      attrs: { type: "danger" },
                      on: { click: _vm.handleCancelModal }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                  ),
                  !_vm.disabledActive && _vm.$can("update", "asset-book")
                    ? _c(
                        "a-button",
                        {
                          key: "ok",
                          attrs: { type: "primary" },
                          on: { click: _vm.handleOk }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_save")) + " ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }