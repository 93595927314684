








































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Messages } from "@/models/enums/messages.enum";
import { assetsServices } from "@/services/assets.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { settingsServices } from "@/services/settings.service";
import { ResponseListOfCoa } from "@/models/interface/settings.interface";
import {
  RequestAssetsbook,
  DataListAssetBook,
  DataListCorporateBook,
} from "@/models/interface/assets.interface";
import { ResponseListMaster } from "@/models/interface/contact.interface";
import { masterServices } from "@/services/master.service";
import {
  PropsModel,
  ResponsePagination,
} from "@/models/constant/interface/common.interface";
import { debounceProcess } from "@/helpers/debounce";
export default Vue.extend({
  data() {
    this.handleSearchCoa = debounceProcess(this.handleSearchCoa, 200);
    this.handleSearchCorporateBook = debounceProcess(
      this.handleSearchCorporateBook,
      200
    );
    return {
      loadingCorporate: false as boolean,
      visible: false as boolean,
      related: false as boolean,
      relatedModal: false as boolean,
      active: true as boolean,
      disabledActive: false as boolean,
      copyAdditions: true as boolean,
      copyAdjustment: true as boolean,
      copyRetirement: true as boolean,
      copyAdditionsModal: true as boolean,
      copyAdjustmentModal: true as boolean,
      copyRetirementModal: true as boolean,
      // dataForEdit: {} as {
      //   name: string,
      //   type: string,
      //   method: string,
      //   id: string,
      //   active: boolean,
      //   relatedCorporateBook?: string,
      //   bookReference: string
      // },
      dataForEdit: {} as DataListAssetBook,
      mode: "Add New" as "Edit" | "Add New",
      search: "" as string,
      dataSource: [] as DataListAssetBook[],
      dataCorporateBook: [] as DataListCorporateBook[],
      columns: [
        {
          title: "Book Name",
          dataIndex: "name",
          key: "name",
          width: 150,
        },
        {
          title: "Type",
          dataIndex: "type",
          key: "type",
          width: 150,
          filters: [],
          filterMultiple: false,
          // onFilter: (value, record) => record.type.indexOf(value) === 0,
        },
        {
          title: this.$root.$t("lbl_action").toString(),
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
          button: ["view", "update"],
          width: 120,
        },
      ],
      loadingTable: false as boolean,
      totalData: 0 as number,
      limit: 10 as number,
      page: 1 as number,
      dataBookType: [] as ResponseListMaster[],
      dataBookMethod: [] as ResponseListMaster[],
      filterColumnBookType: [] as { text: string; value: string }[],
      dataCoa: {} as ResponseListOfCoa,
      isFormSubmitted: false as boolean,
      form: this.$form.createForm(this, { name: "assetbook" }),
      formModal: this.$form.createForm(this, { name: "assetbookModal" }),
      formRules: {
        book: {
          label: "lbl_book_name",
          name: "book",
          id: "book",
          placeholder: "lbl_book_name_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        type: {
          label: "lbl_type",
          name: "type",
          id: "type",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "type",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        method: {
          label: "lbl_method",
          name: "method",
          id: "method",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "method",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        relatedCorporateBook: {
          label: "lbl_related_corporate_book",
          name: "relatedCorporateBook",
          id: "relatedCorporateBook",
          placeholder: "lbl_related_corporate_book_placeholder",
          decorator: [
            "relatedCorporateBook",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        posgainaccount: {
          label: "lbl_proceed_of_sale_gain_account",
          name: "posgainaccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "posgainaccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        poslossnaccount: {
          label: "lbl_proceed_of_sale_loss_account",
          name: "poslossnaccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "poslossnaccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        nbvrgainaccount: {
          label: "lbl_net_book_value_retired_gain_account",
          name: "nbvrgainaccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "nbvrgainaccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        nbvrlossaccount: {
          label: "lbl_net_book_value_retired_loss_account",
          name: "nbvrlossaccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "nbvrlossaccount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        copyAdditions: {
          label: "lbl_copy_additions",
          name: "Copy Additions",
          placeholder: "lbl_copy_additions_placeholder",
        },
        copyAdjustment: {
          label: "lbl_copy_adjustment",
          name: "Copy Adjustment",
          placeholder: "lbl_copy_adjustment_placeholder",
        },
        copyRetirement: {
          label: "lbl_copy_retirement",
          name: "Copy Retirement",
          placeholder: "lbl_copy_retirement_placeholder",
        },
        posClearingAccount: {
          label: "lbl_proceed_of_sales_clearing_account",
          name: "posClearingAccount",
          id: "posClearingAccount",
          placeholder: "lbl_proceed_of_sales_clearing_account_placeholder",
          decorator: ["posClearingAccount"],
        },
        active: {
          label: "lbl_active",
          name: "active",
          placeholder: "lbl_active_placeholder",
        },
      },
      formRulesModal: {
        book: {
          label: "lbl_book_name",
          name: "book",
          id: "book",
          placeholder: "lbl_book_name_placeholder",
          decorator: ["book"],
        },
        type: {
          label: "lbl_type",
          name: "type",
          id: "type",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["type"],
        },
        method: {
          label: "lbl_method",
          name: "method",
          id: "method",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["method"],
        },
        relatedCorporateBook: {
          label: "lbl_related_corporate_book",
          name: "relatedCorporateBook",
          id: "relatedCorporateBook",
          placeholder: "lbl_related_corporate_book_placeholder",
          decorator: ["relatedCorporateBook"],
        },
        posgainaccount: {
          label: "lbl_proceed_of_sale_gain_account",
          name: "posgainaccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["posgainaccount"],
        },
        poslossnaccount: {
          label: "lbl_proceed_of_sale_loss_account",
          name: "poslossnaccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["poslossnaccount"],
        },
        nbvrgainaccount: {
          label: "lbl_net_book_value_retired_gain_account",
          name: "nbvrgainaccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["nbvrgainaccount"],
        },
        nbvrlossaccount: {
          label: "lbl_net_book_value_retired_loss_account",
          name: "nbvrlossaccount",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: ["nbvrlossaccount"],
        },
        copyAdditions: {
          label: "lbl_copy_additions",
          name: "Copy Additions",
          placeholder: "lbl_copy_additions_placeholder",
        },
        copyAdjustment: {
          label: "lbl_copy_adjustment",
          name: "Copy Adjustment",
          placeholder: "lbl_copy_adjustment_placeholder",
        },
        copyRetirement: {
          label: "lbl_copy_retirement",
          name: "Copy Retirement",
          placeholder: "lbl_copy_retirement_placeholder",
        },
        posClearingAccount: {
          label: "lbl_proceed_of_sales_clearing_account",
          name: "posClearingAccount",
          id: "posClearingAccount",
          placeholder: "lbl_proceed_of_sales_clearing_account_placeholder",
          decorator: ["posClearingAccount"],
        },
        active: {
          label: "lbl_active",
          name: "active",
          placeholder: "lbl_active_placeholder",
        },
      },
    };
  },
  watch: {
    filterColumnBookType: function (value) {
      this.columns[1].filters = value;
      this.columns = this.columns.slice();
    },
  },
  methods: {
    handleCancelButton() {
      this.form.resetFields();
    },
    handleOk() {
      this.submitForm("Edit");
    },
    handleTypeForCorporateBookModal(value) {
      if (value.toLowerCase() === "fiscal") {
        this.relatedModal = true;
      } else {
        this.relatedModal = false;
      }
    },
    handleTypeForCorporateBook(value) {
      if (value.toLowerCase() === "fiscal") {
        this.related = true;
      } else {
        this.related = false;
      }
    },
    reponseSearchInput(value) {
      if (value) this.search = `name~*${value}*`;
      else this.search = "";
      this.getListAssetBook();
    },
    handleCreateNew() {
      this.mode = "Add New";
      this.related = false;
      this.form.resetFields();
    },
    ResetFilter() {
      this.search = "";
      this.getListAssetBook();
    },
    onSorterChange(response) {
      if (response.filters) {
        this.search = "";
        for (const key in response.filters) {
          if (
            !this.search &&
            response.filters[key] &&
            response.filters[key].length > 0
          ) {
            this.search = `${key}~${response.filters[key]}`;
          } else if (
            this.search &&
            response.filters[key] &&
            response.filters[key].length > 0
          ) {
            this.search += `_OR_${key}~${response.filters[key]}`;
          }
        }
      }
      this.getListAssetBook();
    },
    handleCancelModal() {
      this.visible = false;
    },
    responseViewTable(response) {
      this.disabledActive = true;
      this.responseEditTable(response, true);
    },
    responseEditTable(response, view) {
      // this.mode = 'Edit'
      if (!view) this.disabledActive = false;
      this.dataForEdit = response.data;
      this.visible = true;
      if (response.data.type.toLowerCase() === "fiscal") {
        this.relatedModal = true;
      } else {
        this.relatedModal = false;
      }
      const {
        // bookReference,
        retiredLossAccount,
        retiredGainAccount,
        // removalLossAccount,
        // removalGainAccount,
        saleLossAccount,
        saleGainAccount,
        name,
        type,
        method,
        active,
        bookCorporateName,
        bookCorporateId,
        copyAddition,
        copyAdjustment,
        copyRetirement,
        saleClearingAccount,
      } = this.dataForEdit;

      let paramsListOfCoa = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;

      // let promise1 = null as Promise<ResponseListOfCoa> | null
      let promise2 = null as Promise<PropsModel> | null;
      let promise3 = null as Promise<PropsModel> | null;
      let promise4 = null as Promise<PropsModel> | null;
      let promise5 = null as Promise<PropsModel> | null;
      let promise6 = null as Promise<PropsModel> | null;
      let promise7 = null as Promise<PropsModel> | null;
      let promise8 = null as Promise<PropsModel> | null;

      if (retiredLossAccount) {
        promise2 = settingsServices.listOfCoa(
          paramsListOfCoa,
          `/${retiredLossAccount.id}`
        );
      }

      if (retiredGainAccount) {
        promise3 = settingsServices.listOfCoa(
          paramsListOfCoa,
          `/${retiredGainAccount.id}`
        );
      }
      if (saleLossAccount) {
        promise6 = settingsServices.listOfCoa(
          paramsListOfCoa,
          `/${saleLossAccount.id}`
        );
      }

      if (saleGainAccount) {
        promise7 = settingsServices.listOfCoa(
          paramsListOfCoa,
          `/${saleGainAccount.id}`
        );
      }

      if (saleClearingAccount) {
        promise8 = settingsServices.listOfCoa(
          paramsListOfCoa,
          `/${saleClearingAccount.id}`
        );
      }

      Promise.all([
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
        promise7,
        promise8,
      ]).then(value => {
        value.map(data => {
          if (data) {
            if (!this.dataCoa.data.some(dataSome => dataSome.id === data.id)) {
              let temp = {
                data: [...this.dataCoa.data, data],
                currentPage: this.dataCoa.currentPage,
                totalPages: this.dataCoa.totalPages,
                totalElements: this.dataCoa.totalElements + 1,
              } as ResponseListOfCoa;
              this.dataCoa = temp;
            }
          }
        });
      });
      this.active = active;
      this.copyAdditionsModal = copyAddition;
      this.copyAdjustmentModal = copyAdjustment;
      this.copyRetirementModal = copyRetirement;
      const checkDataCorporateBook = this.dataCorporateBook.some(
        dataSome => dataSome.id === bookCorporateId
      );
      if (!checkDataCorporateBook && bookCorporateId)
        this.dataCorporateBook = [
          ...this.dataCorporateBook,
          {
            createdDate: "",
            modifiedDate: "",
            name: bookCorporateName as string,
            type: "",
            method: "",
            active: true,
            bookReference: null,
            saleGainAccount: {
              createdDate: "",
              modifiedDate: "",
              description: "",
              id: "",
              code: "",
              balance: 0,
              normalBalance: "",
              parentId: "",
              parentName: "",
              accountType: {
                createdDate: "",
                id: "",
                description: "",
                modifiedDate: "",
                name: "",
              },
            },
            saleLossAccount: {
              createdDate: "",
              modifiedDate: "",
              description: "",
              id: "",
              code: "",
              balance: 0,
              normalBalance: "",
              parentId: "",
              parentName: "",
              accountType: {
                createdDate: "",
                id: "",
                description: "",
                modifiedDate: "",
                name: "",
              },
            },
            removalGainAccount: {
              createdDate: "",
              modifiedDate: "",
              description: "",
              id: "",
              code: "",
              balance: 0,
              normalBalance: "",
              parentId: "",
              parentName: "",
              accountType: {
                createdDate: "",
                id: "",
                description: "",
                modifiedDate: "",
                name: "",
              },
            },
            removalLossAccount: null,
            retiredGainAccount: {
              createdDate: "",
              modifiedDate: "",
              description: "",
              id: "",
              code: "",
              balance: 0,
              normalBalance: "",
              parentId: "",
              parentName: "",
              accountType: {
                createdDate: "",
                id: "",
                description: "",
                modifiedDate: "",
                name: "",
              },
            },
            retiredLossAccount: {
              createdDate: "",
              modifiedDate: "",
              description: "",
              id: "",
              code: "",
              balance: 0,
              normalBalance: "",
              parentId: "",
              parentName: "",
              accountType: {
                createdDate: "",
                id: "",
                description: "",
                modifiedDate: "",
                name: "",
              },
            },
            id: bookCorporateId,
          },
        ];
      this.formModal.setFieldsValue({
        book: name,
        method: method,
        type: type,
        relatedCorporateBook: bookCorporateId,
        // corgainaccount: removalGainAccount?.id,
        // corlossaccount: removalLossAccount?.id,
        nbvrgainaccount: retiredGainAccount?.id,
        nbvrlossaccount: retiredLossAccount?.id,
        posgainaccount: saleGainAccount?.id,
        poslossnaccount: saleLossAccount?.id,
        posClearingAccount: saleClearingAccount?.id,
      });
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListAssetBook();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListAssetBook();
    },
    getListAssetBook() {
      let params = {
        limit: this.limit,
        page: this.page - 1,
      } as RequestQueryParamsModel;
      if (this.search) params.search = this.search;
      this.loadingTable = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataSource = data.data.map((dataMap, idx) => ({
            ...dataMap,
            key: idx,
          }));
          this.totalData = data.totalElements;
        })
        .finally(() => (this.loadingTable = false));
    },
    handleSearchBookMethod(value) {
      let params = {
        name: `BOOK_METHOD`,
      } as RequestQueryParamsModel;
      if (value) params.search = `value~*${value}*`;
      masterServices.listMaster(params).then(data => {
        this.dataBookMethod = data;
      });
    },
    handleSearchBookType(value) {
      let params = {
        name: `BOOK_TYPE`,
      } as RequestQueryParamsModel;
      if (value) params.search = `value~*${value}*`;
      masterServices.listMaster(params).then(response => {
        response.forEach(data => {
          this.filterColumnBookType.push({
            text: data.value,
            value: data.value,
          });
        });
        this.dataBookType = response;
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleSearchCorporateBook(value) {
      let params = {
        limit: 10,
        page: 0,
        search: `active~true`,
      } as RequestQueryParamsModel;

      if (value) params.search += `_AND_name~*${value}*`;

      this.loadingCorporate = true;
      assetsServices
        .listCorporateBook(params)
        .then(data => (this.dataCorporateBook = data.data))
        .finally(() => (this.loadingCorporate = false));
    },
    handleSearchCoa(value) {
      let paramsListOfCoa = {
        page: 0,
        limit: 10,
        search: `isParent~false`,
      } as RequestQueryParamsModel;
      if (value)
        paramsListOfCoa.search =
          `code~*${value}*_OR_description~*${value}*_AND_` +
          paramsListOfCoa.search;
      // list coa
      settingsServices.listOfCoa(paramsListOfCoa, "").then(data => {
        this.dataCoa = data;
      });
    },
    submitForm(type: string): void {
      if (type === "Edit") {
        this.formModal.validateFields((err, values) => {
          if (err) return;
          const datapost = {
            name: values.book,
            method: values.method,
            type: values.type,
            retiredGainAccountId: values.nbvrgainaccount,
            retiredLossAccountId: values.nbvrlossaccount,
            saleGainAccountId: values.posgainaccount,
            saleLossAccountId: values.poslossnaccount,
            bookReferenceId: values.relatedCorporateBook,
            active: this.active,
            copyAddition: this.copyAdditions,
            copyAdjustment: this.copyAdjustment,
            copyRetirement: this.copyRetirement,
            saleClearingAccountId: values.posClearingAccount,
          } as RequestAssetsbook;
          assetsServices
            .updateAssetsbook(datapost, this.dataForEdit.id)
            .then(() => {
              this.formModal.resetFields();
              this.form.resetFields();
              this.$notification.success({
                description: Messages.UPDATE_SUCCESS,
                message: "Success",
                duration: 30,
              });
              this.dataForEdit = {
                name: "",
                type: "",
                method: "",
                id: "",
                active: true,
                bookReference: null,
                bookCorporateName: null,
                bookCorporateId: null,
                createdDate: "",
                modifiedDate: "",
                saleGainAccount: null,
                saleLossAccount: null,
                saleClearingAccount: null,
                // removalGainAccount: null,
                // removalLossAccount: null,
                retiredGainAccount: null,
                retiredLossAccount: null,
                copyAddition: true,
                copyAdjustment: true,
                copyRetirement: true,
              };
              this.visible = false;
              this.related = false;
              this.relatedModal = false;
              this.getListAssetBook();
            })
            .catch(() =>
              this.$notification.error({
                description: Messages.UPDATE_FAIL,
                message: "Error",
                duration: 30,
              })
            )
            .finally(() => (this.isFormSubmitted = false));
        });
      } else if (type === "Add New") {
        this.form.validateFields((err, values) => {
          if (err) return;
          const datapost = {
            name: values.book,
            method: values.method,
            type: values.type,
            retiredGainAccountId: values.nbvrgainaccount,
            retiredLossAccountId: values.nbvrlossaccount,
            saleGainAccountId: values.posgainaccount,
            saleLossAccountId: values.poslossnaccount,
            bookReferenceId: values.relatedCorporateBook,
            active: this.active,
            copyAddition: this.copyAdditionsModal,
            copyAdjustment: this.copyAdjustmentModal,
            copyRetirement: this.copyRetirementModal,
            saleClearingAccountId: values.posClearingAccount,
          } as RequestAssetsbook;
          this.isFormSubmitted = true;
          assetsServices
            .createAssetsbook(datapost)
            .then(res => {
              if (res) {
                this.form.resetFields();
                this.$notification.success({
                  description: Messages.CREATE_SUCCESS,
                  message: "Success",
                  duration: 30,
                });
                this.getListAssetBook();
                this.related = false;
                this.relatedModal = false;
              }
            })
            .catch(() => (this.isFormSubmitted = false))
            .finally(() => (this.isFormSubmitted = false));
        });
      }
    },
  },
  created() {
    this.getListAssetBook();
    this.handleSearchBookMethod("");
    this.handleSearchBookType("");
    this.handleSearchCoa("");
    this.handleSearchCorporateBook("");
  },
});
